import { urlCrypto } from '@particle-network/crypto';
import { Buffer } from 'buffer';
import { Auth } from '../auth';
import { buyUrl, walletUrl } from '../constant';
import { controller } from '../controller';
import { AuthError, CurrencyUnit, OpenBuyOptions, OpenBuyOptionsKeys } from '../types';

export interface WalletConfig {
    topMenuType?: 'close' | 'fullscreen';
}

export function getWalletUrl(auth: Auth, config?: WalletConfig): string {
    if (!auth.isLogin()) {
        throw AuthError.notLogin();
    }
    if (!auth.walletExist()) {
        throw AuthError.walletNotCreated();
    }

    const { projectId, clientKey, appId, chainName, chainId, securityAccount } = auth.config;

    let supportChains = auth.config.wallet?.customStyle?.supportChains || auth.config.wallet?.supportChains;
    if (supportChains && auth.config.chainId && auth.config.chainName) {
        if (
            !supportChains.find(
                (item) =>
                    item.id === auth.config.chainId && item.name.toLowerCase() === auth.config.chainName?.toLowerCase()
            )
        ) {
            supportChains.unshift({
                id: auth.config.chainId,
                name: auth.config.chainName,
            });
        }
    }

    supportChains = supportChains?.map((chain) => {
        const { id, name } = chain;
        return {
            id,
            name,
        };
    });

    const customStyle = auth.config.wallet?.customStyle;
    if (customStyle) {
        customStyle.supportChains = supportChains;
        if (!customStyle.fiatCoin) {
            customStyle.fiatCoin = controller.fiatCoin as CurrencyUnit;
        }
    }

    const params = {
        projectId,
        clientKey,
        appId,
        chainName,
        chainId,
        securityAccount,
        authUserInfo: auth.getUserInfo(),
        authType: auth.getAuthType(),
        authSDKVersion: window.particle?.version,
        supportChains,
    };

    const ciphertext = urlCrypto.encryptUrlParam(params);
    let url = `${walletUrl()}/?params=${ciphertext}&theme=${getWalletUIMode(auth)}&language=${controller.languageCode}`;
    if (customStyle) {
        url += `&customStyle=${encodeURIComponent(Buffer.from(JSON.stringify(customStyle)).toString('base64'))}`;
    }

    const { uiMode, modalBorderRadius } = auth.getAuthTheme();
    url += `&authTheme=${encodeURIComponent(
        Buffer.from(
            JSON.stringify({
                uiMode,
                modalBorderRadius,
            })
        ).toString('base64')
    )}`;

    if (config && config.topMenuType) {
        url += `&topMenuType=${config.topMenuType}`;
    }

    if (controller.erc4337) {
        url += `&erc4337=${controller.erc4337}`;
    }
    return url;
}

export function getBuyUrl(auth: Auth, options?: OpenBuyOptions): string {
    let url = `${buyUrl()}/?language=${controller.languageCode}&theme=${getWalletUIMode(auth)}`;
    const customStyle = auth.config.wallet?.customStyle;
    if (customStyle) {
        const { light, dark } = customStyle;
        url += `&customStyle=${encodeURIComponent(Buffer.from(JSON.stringify({ light, dark })).toString('base64'))}`;
        if (!customStyle.fiatCoin) {
            customStyle.fiatCoin = controller.fiatCoin as CurrencyUnit;
        }
    }

    if (auth.isLogin() && auth.walletExist()) {
        if (options) {
            if (!options.walletAddress) {
                options.walletAddress = auth.getWallet()?.public_address;
            }
            if (!options.network) {
                options.network = auth.config.chainName;
            }
        } else {
            options = {
                walletAddress: auth.getWallet()?.public_address,
                network: auth.config.chainName,
            };
        }
    } else {
        if (!options || !options.network || !options.walletAddress) {
            throw new Error('network or walletAddress not found');
        }
    }

    if (options) {
        Object.keys(options).forEach((key) => {
            const value = options![key as OpenBuyOptionsKeys];
            if (value) {
                url += `&${key}=${encodeURIComponent(value)}`;
            }
        });
    }

    return url;
}

export function getWalletUIMode(auth: Auth): string {
    let walletUIMode = auth.getThemeType();
    if (auth.config.wallet?.uiMode) {
        walletUIMode =
            auth.config.wallet?.uiMode === 'auto'
                ? window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
                    ? 'dark'
                    : 'light'
                : auth.config.wallet?.uiMode;
    }
    return walletUIMode;
}
